.mainContainerInner h2{
    color: var(--fontBlack);
    font-size: var(--headingSize);
    padding-bottom: 30px;
}
.mainContainerInner p{
    color: var(--fontBlack);
    font-size: var(--normalText);
}

.mainContainer{
    margin-top: 90px;
    margin-bottom: 70px;
}

.mainBanner{
    background-image: url("../images/letters.webp");
    height: 480px;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainBanner h1{
    background: #fff;
    padding: 30px;
    color: var(--fontBlack);
    font-size: 35px;
    width: 30%;
    text-align: center;
    border-radius: 10px;
    box-shadow: inset 1px 1px 20px 1px rgb(0 0 0 / 20%);
}

.mainContainerInner{
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
}

.downloadImg{
    height: 90px;
}

.bnrDownload{
    display: flex;
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    justify-content: space-evenly;
    padding: 20px;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.brnText{
    color: var(--whiteFont);
    font-size: 28px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.brnText a{
    color: var(--whiteFont);
}

.form{
    display: flex;
    gap: 50px;
    margin-top: 30px;
}
.formLeftInner{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.formRightInner{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.form input{
    padding: 10px 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid lightgray;
}
.form textarea{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 30px 15px;
}
.form button{
    background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    color: var(--whiteFont);
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    width: max-content;
    margin-top: 23px;
}
.socialImg{
    height: 40px;
    padding: 0px 10px 0px 0px;
}

@media only screen and (max-width: 1000px) {
    .mainContainer{
        margin-top: 0px;
    }
    .mainBanner{
        height: 300px;
    }
    .mainBanner h1{
        padding: 15px;
        font-size: var(--headingSize);
        width: 60%;
    }
    .bnrDownload{
        flex-direction: column;
    }
    .brnText{
        font-size: var(--headingSize);
    }
    .downloadImg{
        height: 60px;
    }
    .form{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .formLeftInner{
        width: 100%;
    }
    .formRightInner{
        width: 100%;
    }
}