:root{
    --headingSize:20px;
    --bigText:18px;
    --normalText:16px;
    --smallText:14px;
    --mainColor:#E2120B;
    --fontBlack: #202124;
    --whiteFont: #fff;
    --bold: 900;
    --lineHeight: 1.6rem;
    --borderBottomContainerColor: #cecece;
}

.newsContainer{
    width: 100vw;
    margin-top: 100px;
    margin-bottom: 100px;

    
}

.newContainerInner{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--borderBottomContainerColor);
    width:60%;
    margin: 20px auto;
}

.newsMainImg{
    height: 90px;
    width: auto;
    background-position: center;
    background-size: cover;
}

.newsDivImg{
    width: 20vw;

}

.newsTitle{
    color: var(--fontBlack);
    font-weight: var(--bold);
    font-size: var(--smallText);
}

.newsDate{
    color: var(--fontBlack);
    font-size: var(--smallText);
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}
.newsDate img{
    width: 20px;
    margin-right: 3px;
    
}

.newsDetailsDiv{
    padding:0px 15px;
    width: 60vw;
}