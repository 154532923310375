.ChangePassContainer{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.ChangePassContainer input{
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid lightgrey;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 5%);
    width: 250px;
}
.ChangePassContainer input:focus{
    outline: 0px;
    box-shadow: inset 0px 0px 20px 0px rgb(0 0 0 / 5%);
}
.SubmitBtn{
    background:linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%);
    color:#fff;
    font-weight: bold;
    width: max-content;
    padding: 10px 20px !important;
    cursor: pointer;
}
.FormContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
}
.FormText{
    padding-bottom: 30px;
    font-size: var(--bigText);
    text-align: center;
    color: var(--fontBlack);
}
.Required{
    display: none;
}
.RequiredTrue {
    display: block;
    font-size: 13px;
}
.validCode{
    display: none;
}
.validCodeTrue{
    display: block;
    font-size: 13px;
    width: 250px;
}
.requiredCode{
    display: none;
}
.requiredCodeTrue{
    display: block;
    font-size: 13px;
}
.validConfirmCode{
    display: none;
}
.validConfirmCodeTrue{
    display: block;
    font-size: 13px;
}
.validcurrentpass{
    color: var(--mainColor);
    font-size: 13px;
}