
.backgroundStyle{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url(../images/redlines2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 65% 50%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mainContainer{

    display:flex;
    flex-direction:column;
    background-color: white;
    border-radius: 25px;
    width: 30%;
    margin:0 auto;
    padding:25px;
    color:#202124;
    border:solid rgba(227,18,13,1) 2px;
    box-shadow: rgb(0, 0, 0,0.5) 1px 0 10px;;
}

.mainContainerContent{
    text-align: center;
}

.overallPrice{
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.price{
    font-size: 30px;

}
.month{
    padding-top: 8px;
    font-weight: bold;
}
.register{
    font-size: 23px;
    padding:0px;
    margin-bottom: 15px;
    margin-top: 15px;
}
.toGet{
    text-align: left;
    padding-bottom: 25px;
    font-style: italic;
}
.padded{
    vertical-align: bottom;
    margin-right: 3px;
    
}

.joinUs{
    border-radius: 9px;
    background-color:rgba(227,18,13,1);
    border: none;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    width: fit-content;
    cursor: pointer;
    margin: auto;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }


.joinUs:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    right: -20px;
    transition: 0.5s;
    padding-right: 5px;
  }
  .joinUs:hover {
    padding-right: 25px;
    
  }
  
  .joinUs:hover:after {
    opacity: 1;
    right: 0;
    
  }
.maybeAfter{
    margin-top: 20px;
    border:none;
    font-size: 13px;
    background: none;
    text-decoration: underline;
}

