:root{
    --mainColor:#E2120B;
    --fontBlack: #202124;
    --backgroundBlack: #191919;
    --whiteFont: #fff;
    --bold: 900;
    --headingSize:20px;
    --bigText:18px;
    --normalText:16px;
    --smallText:14px;
    --extrasmallText:12px;
    
}
@font-face {
    font-family: AvertaBlack;
    src: url(../fonts/averta/averta-black.otf);
  }
@font-face{
    font-family: AvertaBold;
    src: url(../fonts/averta/Averta-Bold.ttf);
}
@font-face{
    font-family: AvertaLight;
    src: url(../fonts/averta/Averta-Light.ttf); 
}
@font-face{
    font-family: CeraBold;
    src: url(../fonts/cera/Cera\ GR\ Bold.woff.ttf);
}
@font-face{
    font-family: CeraBlack;
    src: url(../fonts/cera/cera-gr-black.otf);
}
@font-face{
    
    font-family: CeraLight;
    src: url(../fonts/cera/cera-gr-light.otf);
}
@font-face{
    font-family: CeraMedium;
    src: url(../fonts/cera/cera-gr-medium.otf);
}

.header{
    background-color: var(--mainColor);
    height: 75px;
    font-family: AvertaLight;
    font-size: 16px;
}
.headerLogo{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.372);
}
.headerDaily{
    font-family: AvertaBold;
    margin-left:4px;
}

.SideBarButton{
    background-color: var(--mainColor);
    color: white;
    font-weight: bold;
    padding:5px 10px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px black;
    text-align: start;
    transition:all 0.2s;
    max-width: 170px;
    font-family: AvertaBold;  
    white-space: nowrap;
}

.SideBarButton:hover {
    background-color: black;
    transition:all 0.2s;
    color: white;
    font-weight: bold;
    padding:5px 10px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px var(--mainColor);
    text-align: start; 
    transform: scale(1.02); 
    font-family: AvertaBold;   
}

.SideBarButtonActive {
    background-color: black;
    transition:all 0.2s;
    color: white;
    font-weight: bold;
    padding:5px 10px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px var(--mainColor);
    text-align: start;
    max-width: 170px; 
    transform: scale(1.02); 
    font-family: AvertaBold;
}

.loadMoreButton{
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: var(--mainColor);
    color: white;
    font-weight: bold;
    padding:5px 10px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px black;
    text-align: start;
    transition:all 0.2s;
    max-width: 170px;
    font-family: AvertaBold;  
    white-space: nowrap;
}

.loadMoreButton:disabled {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: gray;
    color: white;
    font-weight: bold;
    padding:5px 10px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px grey;
    text-align: start;
    transition:all 0.2s;
    max-width: 170px;
    font-family: AvertaBold;  
    white-space: nowrap;
}

.bottomBarButton {
    background-color: transparent;
    color: white;
    border: none;
}

.AdvertStyle{
    max-width: 250px;
    max-height: 250px;
    height: 220px;
    background-image:url(../images/TX-digital-school.gif);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.AdvertStyleThird{
    max-width: 250px;
    max-height: 250px;
    height: 220px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.AdvertStyleTop{
    max-width: 250px;
    max-height: 250px;
    height: 220px;
    background-image:url(../images/dei.gif);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.moreButtonContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner{
    width: 100%;
    background-color: var(--fontBlack);
    border-radius: 9px;
    padding:20px;
    display: flex;
    color:white;
    gap:25px;
    font-family: AvertaLight;
    align-items: center;
}

.homePageBorders{
    width: 100%;
    border-bottom: solid 3px var(--mainColor);
    border-radius: 9px;
}
.homePageNewAnaly{
    font-family: CeraBold;
    padding:0px 12px 0px 12px;
}

.homePageNewsBackground{
    height: 200px;
    background-image:url(../images/city.jpg);
    background-size: cover;
    background-position: center;
}

.homePageNewsTitle{
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(240, 240, 240);
    padding:10px 20px;
    font-family: CeraBlack;
}

.homePageNewsDetails{

    padding:0px 10px 10px 20px;
    background-color: rgb(240, 240, 240);
    height: 200px;
    overflow-y: scroll;
    font-family: CeraLight;
}
.homePageNewsDetails::-webkit-scrollbar{
    display:none;
}

.homePageSideNewsTitle{
    font-size: 20px;
    font-weight: bold;
    padding:10px 20px;
    padding-bottom: 5px;
    font-family: CeraBlack;
}

.homePageSideNewsDetails{
    padding:10px 20px;
    height: 150px;
    overflow-y: scroll;
    font-family: CeraLight;

}
.homePageSideNewsDetails::-webkit-scrollbar{
    display:none;
}

.lineWithDot{
    padding:0px 20px;
    width: fit-content;
    height: 7px;
    display: flex;
}

.line{
    background-color: var(--mainColor);
    /* width: 70px; */
    height: 100%;
    border-radius: 9px;
}

.dot{
    background-color: var(--mainColor);
    width: 7px;
    height: 100%;
    margin-left:5px;
    border-radius: 50%;
}

.backgroundImgStyle{
    background-image: url(../images/city.jpg);
    background-position: center;
    background-size: cover;
    flex:1;

}

.articleInfo{
    background-color: rgb(240, 240, 240);
    margin-left:-50px;
    margin-top:20px;
    margin-bottom:20px;
    display:flex;
    flex:1;
    position: relative;
    
}

.authorStyle{
    font-family: AvertaLight;
    background-color: var(--mainColor);
    color: white;
    font-weight: bold;
    padding:0px 10px;
    font-size: 12px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px black;
    text-align: start;
    width: fit-content;
    position: absolute;
    right:20px;
    transform: translateY(-50%);
}

.underLine{
    position: absolute;
    right:-5px;
    transform: translateY(50%);
    bottom: 0px;
}

.listedArticleDate{
    font-size: 12px;
    padding:0px 20px;
    padding-top:10px;
    font-family: CeraLight;
}

.listedArticleTitle{
    font-size: 22px;
    font-weight: bold;
    padding:10px 20px;
    font-family: CeraBlack;
}
.listedArticleDetails{
    padding:0px 10px 10px 20px;
    overflow-y: scroll;
    flex:1;
    font-family: CeraLight;
    

}

.listedArticleDetails::-webkit-scrollbar{
    display:none;
}

.listedArticle{
    display:flex;
    width:100%;
    flex-direction: row;
    height: 250px;
    margin-top: 35px;
}

.dateLogo{
    width: 12px;
    margin-right: 5px;

}

.bottomBarStyle{
    padding:5px;
    background-color: var(--fontBlack);
    color: white;
    display:flex;
    flex-direction: row;
    justify-content:center ; 
    gap:25px;
    font-size: small;
}

.insideArticle{
    display:flex;
    flex-direction: column;
    
}
.insideArticleImage{
    background-image:url(../images/city.jpg);
    background-size: cover;
    height: 400px;
    background-position: center;
}

.insideArticleInfo{
    flex:1;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgb(177 177 177 / 25%);
    padding-bottom: 30px;
    margin-left:10px;
    margin-right: 20px;
    position: relative;
    transform:translateY(-80px);
}

.insideAuthorStyle{
    font-family: AvertaLight;
    background-color: var(--mainColor);
    color: white;
    font-weight: bold;
    padding:0px 10px;
    font-size: 16px;
    border: 0px;
    border-right: solid white 3px;
    box-shadow: 7px 0px 0px 0px black;
    text-align: start;
    width: fit-content;
    position: absolute;
    right:80px;
    transform: translateY(-50%);
}

.insideArticleDate{
    font-size: 12px;
    padding:0px 50px;
    padding-top:30px;
    font-family: CeraLight;
}
.AudioDisplaytext{
    border-width: 1px 0px 1px 1px;
    border-color: rgb(170, 170, 170);
    border-style: solid;
    padding: 0px 30px 0px 20px;
    border-radius: 20px;
    margin-right: -35px;
    font-weight: 700;
    height: 34px !important;
    background: linear-gradient(to right top, rgb(233, 233, 233) 0%, rgb(255, 255, 255) 100%) !important;
    font-size: 19px !important;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    transform: scale(.7);
}
.insideArticleTitle{
    font-size: 22px;
    font-weight: bold;
    padding:10px 50px;
    font-family: CeraBlack;
    
}

.insideArticleDetails{
    padding:0px 50px 10px 50px;
    /* overflow-y: scroll;
    height: 500px; */
    font-family: CeraLight;
}
.insideArticleDetails::-webkit-scrollbar{
    display: none;
}

.insideArticleSocials{
    width: 3px;
    background-color: var(--mainColor);
    border-radius: 0px 0px 9px 9px;
    height: 400px;
    position: relative;
    gap:25px;

}
.footerLink{
    color: #fff;
    text-decoration: none;
}
.footerLink:hover{
    color: #fff;
    font-weight: bold;
}

.socials{
    background-color: var(--mainColor);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-left: 1px;
    transform: translateX(-50%) translateY(20px);
}
@media only screen and (max-width: 1000px) {
    .insideArticleSocials{
        height: 0px;
        width: 100%;
        gap:0px;
        justify-content: space-evenly;

    }
    .insideArticleInfo{
        transform: translateY(0px);
        margin:0 auto;
        background: none;
        box-shadow: none;
        border-radius: 0;
    }
    .socials{
        transform: translateX(0px) translateY(0px) !important;
        margin-top:5px;
    }
    .AdvertStyleMob{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .AdvertStyle{
        margin-top: 0px;
    }
    .homeCategoriesMob{
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }
    .gapSocial{
        margin-top: 100px;
    }
    .insideArticleSocials{
        top: 30px;
    }
    .listedArticleTitle{
        font-size: var(--smallText);
        
    }
    .listedArticleDetails{
        font-size: var(--extrasmallText);
    }
    .listedArticle{
        height: 200px;
    }
    .bottomBarStyle{
        gap: 10px;
    }
    .insideArticleImage{
        height:200px;
    }
    .insideArticleDate{
        padding:0px 20px;
    }
    
    .insideArticleTitle{
        padding:10px 20px;
    }
    
    .insideArticleDetails{
        padding:0px 20px 10px 20px;
        overflow-y: scroll;
        height: unset;
    }
    .insideAuthorStyle{
        right: 30px;
    }
    .homePageNewsTitle{
        background-color: unset;
    }
    
    .homePageNewsDetails{
        background-color: unset;
        height: unset;
    }
    .lineWithDot{
        height: 4px;
    }
    .dot{
        width: 4px;
    }
    .banner{
        display: none;
    }
}

