

:root{
    --headingSize:20px;
    --bigText:18px;
    --normalText:16px;
    --smallText:14px;
    --mainColor:#E2120B;
    --fontBlack: #202124;
    --whiteFont: #fff;
    --bold: 900;
    --lineHeight: 1.6rem;
    --borderBottomContainerColor: #cecece;
}

.mainContainer{
    margin-top: 70px;
    height: 100vh;
    width: 100%;
    position: fixed
}
.mainBanner{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #202124;
    height:55%;

}
.bannerTextStyle{
    font-size: 52px;
    font-weight: bolder;
    color:white;
    text-shadow: rgb(0, 0, 0) 1px 0 10px;

}
.mainButtons{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 45%;
}
.contentStyle{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:35px;
    margin-bottom: 100px;
    width: 200px;
}
.textStyle{
    font-size: medium;
    margin:15px;
    font-weight: bold;
    text-align: center;
}
.buttonStyle{
    border-radius: 9px;
    background-color:white;
    width: 200px;
    color:black;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    width: fit-content;
    cursor: pointer;
    margin: auto;
    display: inline-block;
    position: relative;
    border:black 2px solid;
    transition:0.2s;
}


.buttonStyle:hover{
    background-color:lightgray;
    transition:0.2s;
}

/* .articleContainer{
    background:white;
    height: 100vh;
    width: 60%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.articleContainerInner{
    display:flex;
    flex-direction:column;
    width: 90%;
    margin:0 auto;
    color:#202124;
}

.headerStyleContact{
    font-size: var(--headingSize);
    text-align: center;
    margin-bottom: 30px;
    font-weight: var(--bold);
    color:#202124;
    text-decoration: var(--mainColor) underline;
    
}
.headerStyleSocial{
    margin-top: 30px;
    font-size: var(--headingSize);
    text-align: center;
    margin-bottom: 22px;
    font-weight: var(--bold);
    color:#202124;
    text-decoration: var(--mainColor) underline;
    
}

.contentStyle{
    display:flex;
    font-size: var(--smallText);
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--borderBottomContainerColor);

}

.contentStyleMedia{

    font-size: var(--normalText);
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--borderBottomContainerColor);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

}

.padded{
    margin-right: 5px;
    align-self: center;
}
.paddedSocial{
    margin:0px 15px 0px 15px ;
    align-self: center;
} */