:root {
    --headingSize: 20px;
    --bigText: 18px;
    --normalText: 16px;
    --smallText: 14px;
    --mainColor: #E2120B;
    --fontBlack: #202124;
    --whiteFont: #fff;
    --bold: 900;
    --lineHeight: 1.6rem;
    --borderBottomContainerColor: #cecece;
}
.required{
    font-size: 12px;
    color: var(--mainColor);
    display: none;
}
.Background {
    background: #191919;
    background-image: linear-gradient(-180deg, rgb(255 255 255 / 10%) 0%, rgb(0 0 0 / 50%) 100%);
    background-blend-mode: lighten;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    border-radius: 5%;
    transition: all 500ms;
}
.UpperText {
    padding: 5px 10px 5px 10px;
    color: var(--whiteFont);
    font-size: 35px;
    width: fit-content;
}
.loginInsertCodeStyle {
    background: linear-gradient(90deg, rgba(227, 18, 13, 1) 0%, rgba(32, 33, 36, 1) 200%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    /* width: 150px; */
    text-align: center;
    cursor: pointer;
}
.insideContainerForgotPass {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    transition: all 500ms;
    border: 1px solid lightgray;
}
.loginForgotPassStyle {
    background: linear-gradient(90deg, rgba(227, 18, 13, 1) 0%, rgba(32, 33, 36, 1) 200%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    padding: 8px 15px;
    border-radius: 10px;
    text-align: center;
}
.loginForgotPassStyle:hover {
    cursor: pointer;
}
.insideContainerEmailVer {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    transition: all 500ms;
    border: 1px solid lightgray;
}
.inputStyle {
    border-radius: 30px;
    color: var(--fontBlack);
    padding: 10px 15px;
    width: 300px;
    outline: none;
    border: 1px solid lightgray;
    transition: all 500ms;
}
.imageStyle{
    position: fixed;
    left: 850px;
}
.information {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 15px 0px 15px 0px;
}
.insideContainer {
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    transition: all 500ms;
    border: 1px solid lightgray;
}
.insideContainerv2 {
    gap: 5px;
    background-color: white;
    height: 45vh;
    width: auto;
    font-size: 40px;
    font-weight: lighter;
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 /20%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 30px;
    transition: all 500ms;
    border: 3px solid lightgray;
}
.signup {
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    color: #8e8e8e;
    font-size: var(--smallText);
}
.signupv2 {
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    color: #fff;
    font-size: var(--smallText);
    margin-top: 10px;
}
.signupColored {
    color: var(--mainColor);
    font-weight: var(--bold);
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    margin-left: 4px;
}
.errorMessage{
    text-align: center;
}
.forgotpass {
    width: max-content;
    color: #8e8e8e;
    font-size: 14px;
}
.forgotpass:hover {
    cursor: pointer;
}
.loginStyle {
    background: linear-gradient(90deg, rgba(227, 18, 13, 1) 0%, rgba(32, 33, 36, 1) 200%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    width: 250px;
    text-align: center;
}
.loginStyle:hover {
    cursor: pointer;
}
.loginStylev2 {
    background: linear-gradient(90deg, rgba(227, 18, 13, 1) 0%, rgba(32, 33, 36, 1) 200%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    padding: 8px 15px;
    border-radius: 10px;
    text-align: center;
    border: none;
}
.loginStylev2:hover {
    cursor: pointer;
}
.passwordinfo {
    font-size: 11px;
}
.emailSentStyle {
    width: fit-content;
    color: #8e8e8e;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.loginPasswordResetedStyle {
    background: linear-gradient(90deg, rgba(227, 18, 13, 1) 0%, rgba(32, 33, 36, 1) 200%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    width: 150px;
    text-align: center;
    border: none;
    cursor: pointer;
}
.emailSentStyle {
    width: fit-content;
    color: #8e8e8e;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.insideContainerChangePass {
    background-color: white;
    gap: 10px;
    height: 45vh;
    width: auto;
    font-size: 40px;
    font-weight: lighter;
    color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 /20%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    border-radius: 30px;
    transition: all 500ms;
    border: 3px solid lightgray;
}
.errorvalidation{
    display: none;
}
.errorvalidationTrue{
    display: block;
    color:var(--mainColor);
    font-size: 13px;
    padding-bottom:20px;
}