
.mainArticleContainer{
    padding: 25px;
    margin-bottom: 100px;
}
p{
    color: var(--fontBlack);
    line-height: 1.6;
}

.headerStyle{
    font-size: 35px;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 90px;
    font-weight: 700;
}

.ContentStyle{
    color:#202124;
    width: 90%;
    margin: 0 auto;
    text-align: justify;
}

.mainArticleContainer li{
    margin-left: 50px;
}
.privacyText{
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
    margin-bottom: 50px;
}
