
.topbarcontainer{
    /* background: linear-gradient(90deg, rgba(227,18,13,1) 0%, rgba(32,33,36,1) 200%); */
    background: var(--backgroundBlack);
    width: 100vw;
    height: 2px;
    position: fixed;
    top: 90px;
    left: 0;
    z-index: 1;
}
.NavContainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    margin: 0 auto;
    position: fixed;
    left: 0px;
    background: #fff;
    width: 100%;
    top: 0px;
    padding: 0% 5% 0% 5%;
}
.logo{
    height: 50px;
}

.LoginImg{
    height: 24px;
}
.LoginText{
    color: #981816;
    font-weight: bold;
    padding-left: 10px;
}
.LoginDiv{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.datetimeText{
    font-weight: var(--bold);
    color: var(--fontBlack);
    font-size: var(--normalText);
    text-align: center;
}
.topbarcontainerInner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 90vw;
    margin: 0 auto;
}

.boxes{
    background:none;
    border: none;
    border-radius: 10px;
    display: inline-block;
    align-items: center;
    height:50px;
    width: 150px;
    transition:0.2s;
}

.boxesInner{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    color:white;
}
.settingsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.SettingsDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 40px !important;
    cursor: pointer;
}
.SettingsDiv img{
    width: 24px;
}
.boxes img{
    width: 20px;
    filter: brightness(0) invert(1);
}
.Textbox{
    font-size: 13px;
    text-align: center;
    margin-left: 9px;
}

.navbarContainer{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width:200px;
    align-items: center;
    width: 100%;
}
.ChangePassDiv{
    display: none;
}
.ChangePassDivClicked{
    display: block;
    position: absolute;
    border-top-left-radius: 15px;
    top: 65px;
    max-width: max-content;
    right: 5%;
    background:#851918;
}
.ChangePassDivClicked a{
    color: #fff;
    padding: 15px 35px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #fff;
}

.Textbox:hover{
    font-weight: var(--bold);
    transition:0.2s;
}
.boxes:active{
    margin-top: 10px;

}
.LogoutText{
    cursor:pointer;
    color: #981816;
    font-weight: bold;
    padding-left: 10px;
}
.mainTitle{
    font-size: 24px;
}

